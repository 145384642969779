.page-content {
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.nftHeading h2 {
  color: white;
  font-size: 18px;
  margin: 18px 0;
}

.timeree {
  font-size: 30px;
  color: red;
  font-weight: 700;
}

.text_color {
  color: white !important;
  font-size: 18px;
}

.textcolor {
  color: black !important;
  font-size: 15px;
  padding: 16px 0px 0px 0px;
  margin-right: 20px;
}

#myProgress {
  width: 100%;
  background-color: #fff0fe;
}

#myBar {
  width: 1%;
  height: 20px;
  background: #000000;
}

.mb_1 {
  margin-bottom: 1rem;
}

.qrcodeimage {
  width: 150px;
  height: 150px;
  margin: auto;
}

.timerdiv {
  transition: all 0.3s ease-in-out;
  box-shadow: 0 0 2px 3px rgb(0 245 255);
}

.timerdiv:hover {
  background-color: rgb(0 245 255);
  box-shadow: unset;
}

.timerdiv:hover p {
  color: black !important;
}

.timerdiv p {
  color: white !important;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}

@media screen and (min-width: 1400px) {
  .page-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.cole {
  border: 1px solid rgb(0 245 255);
  padding: 7px 20px;
  font-size: 18px;
  width: auto;
  display: inline-block;
}

.page-wrapper {
  height: 100%;
  /* margin-top: 100px; */
  margin-bottom: 3rem;
  margin-left: 0px;
}

.popular_nfts {
  position: relative;
}

.popular_nfts .nftTimeItem {
  /* top: 143px; */
  top: 105px;
  left: 48%;
  transform: translate(-50%, -50%);
  text-align: center;
  position: absolute;
  background-color: transparent;
  box-shadow: 0 0 2px 3px rgb(0 245 255);
  padding: 29px 19px;
  margin: 14px;
  width: 100%;
  /* height: max-content; */
  height: 250px !important;
  border-radius: 4px;
}

.popular_nfts .nftTimeItem h2,
.popular_nfts .nftTimeItem h1 {
  font-size: 18px;
  margin-bottom: 0;
  color: white;
}

.popular_nfts .nftTimeItem p {
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  color: white;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain {
  zoom: 90%;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart {
  margin: 0 auto;
  position: relative;
  zoom: 95%;
  font-size: 120px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #000;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart span {
  width: 3.33em;
  line-height: 3.33em;
  font-size: 0.3em;
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  display: flex;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  color: rgba(255, 255, 255, 0.7);
  font-family: "sans-serif";
  font-style: normal;
  user-select: none;
  -webkit-touch-callout: none;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart::after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background: linear-gradient(153.09deg, #000 -14.22%, #363636 91.92%);
  width: 0.84em;
  height: 0.84em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}

.popular_nfts .nftTimeItem .TotalEarnChatMain .priceChart .bar {
  position: absolute;
  border-image: linear-gradient(
    153.09deg,
    #a7eb3d -14.22%,
    rgb(229 184 11) 91.92%
  );
  border-image-slice: 1;
  width: 117px;
  height: 119px;
  clip: rect(0, 0.8em, 1em, 0);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  border: 0.08em solid transparent;
  background: linear-gradient(
      153.09deg,
      rgb(0 232 242) -14.22%,
      rgb(0 231 240) 91.92%
    )
    border-box;

  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.popular_nfts .nftTimeItem button {
  background-color: #000;
  color: #fff;
  border: 1px solid rgb(0 245 255);
  padding: 2px 12px;
  font-size: 14px;
  width: 100%;
  margin-top: 12px;
}

.mt30hsff {
  margin-top: 240px;
}

.mt30hsff2 {
  margin-top: 15px;
}

.SellersItem {
  display: flex;
  justify-content: center;
  /* box-shadow: 0 0px 27px -10px rgb(64 237 71); */
  border-radius: 5px;
  padding: 9px;
  border: 1px solid rgb(0 245 255);
  transition: all 0.3s ease-in-out;
}

.SellersItem .SellersText {
  padding-top: 10px;
  width: 100%;
  color: white;
}

.SellersItem .SellersText h2 {
  font-size: 15px;
  margin-bottom: 7px;
  text-align: center;
}

.SellersText .timetext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.SellersText .timetext span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SellersText .timetext span img {
  filter: invert(1);
}

.Img_profile {
  position: relative;
  top: 85px;
}

.img_p {
  width: 60px;
  border-radius: 50%;
  box-shadow: 0 0 0px 1px rgb(0 245 255);
}

.zackItem {
  margin-bottom: 11px;
  margin-top: 57px;
}

.zackItem .profileSection {
  color: white;
  border-radius: 5px;
  padding: 46px 15px 68px 15px;
  border: 1px solid rgb(0 245 255);
  text-align: center;
}

.zackItem .profileSection h2 {
  font-size: 16px;
}

.binerincome span {
  width: 60px;
  height: 60px;
}

.cardscards {
  overflow-y: auto;
  height: 438px;
}

.maincard {
  border: 2px solid rgb(0 245 255);
  transition: all 0.3s ease-in-out;
}

.card {
  background-color: transparent;
  box-shadow: unset;
  border: unset;
}

.mycard {
  background-color: rgb(0 245 255 / 60%) !important;
  border-radius: 10px;
}

.popular_nfts {
  position: relative;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  color: white;
}

.nftteam2nd {
  transform: unset !important;
  text-align: unset !important;
  position: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
  padding: unset !important;
  margin: unset !important;
  width: unset !important;
  border-radius: unset !important;
}

.card {
  background-color: transparent;
  box-shadow: unset;
  border: unset;
}

.mycard2 {
  background-color: rgb(0 245 255 / 60%) !important;
}

.card {
  background-color: transparent;
  box-shadow: unset;
  border: unset;
}

.myteamcard {
  border: 2px solid rgb(0 245 255) !important;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  height: 100%;
  overflow-y: auto;
  height: 438px;
  color: white;
}

.myteamcard .card-header {
  border-bottom: 1px dashed #26365f;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.bgmain {
  background-color: #26365f !important;
}

.myteamcard .cardteam {
  background-color: #258e37;
  margin-top: 5px;
  color: white;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.myteamcard .cardteam .btn1 {
  background-color: rgb(229 184 11);
  width: 100%;
  height: 100%;
  padding: 17px 10px !important;
  color: white !important;
}

.listcricle .circle-small {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 20px;
}

.listcricle .circle-small .text {
  position: absolute;
  width: 100%;
  top: 26px;
  font-size: 17px;
  text-align: center;
  font-weight: 400;
  line-height: 16px;
  color: white;
}

.listcricle .circle-small svg {
  width: 80px;
  height: 80px;
}

.listcricle .circle-small .bg {
  fill: none;
  stroke-width: 6px;
  stroke: #343035;
}

.listcricle .bg {
  min-height: 100vh;
  padding-bottom: 60px;
  padding-top: 80px;
  /* background: url(../img/back-desktop.5b69385c.jpg) no-repeat 50%; */
  background-size: cover;
  background-attachment: fixed;
}

.myteamcard:hover {
  box-shadow: inset 0px -1px 12px 3px rgb(0 245 255) !important;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.TableMain {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* margin-top: 65px; */
  border-collapse: collapse;
  width: 100%;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 800px) {
  .resSeb {
    /* margin-top: 360px; */
    margin-bottom: 20px;
  }

  .reassa {
    height: max-content !important;
  }
}

.bar {
  border: 0 !important;
}

.ksjhajq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 370px) {
  .startedsection .copytext {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 550px) {
  .popular_nfts .nftTimeItem button {
    font-size: 10px;
    width: 47% !important;
  }

  .leffttf {
    margin-right: 5px;
  }

  .mt30hsff {
    margin-top: 305px;
  }

  .popular_nfts .nftTimeItem {
    left: 46%;
  }
}

.fs-14 {
  font-size: 14px;
}

.positioncss {
  position: unset !important;
  transform: unset !important;
  margin: 0 !important;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .page-wrapper {
    margin-top: 150px;
  }

  .zackItem .profileSection {
    padding: 49px 0px 68px 0px !important;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .zackItem .profileSection {
    padding: 46px 5px 68px 5px !important;
  }

  .cardscards {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .fontss {
    font-size: 14px;
  }

  .listcricle .circle-small {
    width: 70px;
    height: 70px;
  }

  .zackItem .btn_hxn {
    width: 100% !important;
  }

  .popular_nfts .nftTimeItem {
    height: 100% !important;
  }

  .timerdiv p {
    font-size: 20px !important;
    padding: 5px;
  }
}

/*  New Dashboard css  */
.page-wrapper {
  background: #1a233a;
}

.NewDashoard {
  background: #1a233a;
  overflow-x: hidden;
}

.NewDashoard .content-area {
  background: #1a233a;
}

.NewDashoard .content-area .card-top {
  border-radius: 24px;
  height: 100%;
  padding: 16px;
  /* background-image: url(../../../public/assets/image/background1card.jpg);
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: #272e48;
}

.NewDashoard .content-area .card-top .card-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
}

.NewDashoard .content-area .card-top .card-heading .headingcard {
  font-size: 18px;
  color: white;
  font-weight: 600;
}

.NewDashoard .content-area .card-top .card-heading .iconimage {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 10px;
  top: 0px;
}

.NewDashoard .content-area .card-top .payment {
  font-size: 20px;
  color: white;
  font-weight: 600;
  margin-top: 10px;
}
.NewDashoard .content-area .card-top .premium {
  font-size: 12px;
  color: white;
  font-weight: 600;
  margin-top: 10px;
}
.heads{
  color: white;
}

.NewDashoard .content-area .card-top .bottomcardcontent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top:35px;
}

.NewDashoard .content-area .card-top .bottomcardcontent .leftside {
  width: 47%;
  border-right: 1px solid white;
}

.NewDashoard .content-area .card-top .bottomcardcontent .leftside .leftamoun {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 14px;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
}

.NewDashoard
  .content-area
  .card-top
  .bottomcardcontent
  .leftside
  .leftamoun
  span {
  transform: rotate(30deg);
  color: green;
  box-shadow: 0px 0px 10px green;
  background: #00800047;
  width: auto;
}

.NewDashoard
  .content-area
  .card-top
  .bottomcardcontent
  .leftside
  .leftamoun
  span
  i {
  font-size: 25px;
}

.NewDashoard .content-area .card-top .bottomcardcontent .leftside .leftpayment {
  font-size: 14px;
  font-weight: 600;
  color: white;
  margin-left: 20px;
}

.NewDashoard .content-area .card-top .bottomcardcontent .rightside {
  width: 47%;
}

.NewDashoard .content-area .card-top .bottomcardcontent .rightside .rightamoun {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 14px;
  font-weight: 600;
  color: white;
  margin-bottom: 10px;
}

.NewDashoard
  .content-area
  .card-top
  .bottomcardcontent
  .rightside
  .rightamoun
  span {
  transform: rotate(30deg);
  color: green;
  box-shadow: 0px 0px 10px green;
  background: #00800047;
  width: auto;
}

.NewDashoard
  .content-area
  .card-top
  .bottomcardcontent
  .rightside
  .rightamoun
  span
  i {
  font-size: 25px;
}

.NewDashoard
  .content-area
  .card-top
  .bottomcardcontent
  .rightside
  .rightpayment {
  font-size: 14px;
  font-weight: 600;
  color: white;
  margin-left: 20px;
}

.NewDashoard .content-area .card-top-left {
  border-radius: 24px;
  height: 100%;
  padding: 16px;
  /* background-image: url(../../../public/assets/image/background1card.jpg);
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: #272e48;
}

.NewDashoard .content-area .card-top-left .card-heading {
  font-size: 18px;
  color: white;
  font-weight: 600;
}

.NewDashoard .content-area .card-top-left .leftrightcontentcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .leftsidecontent {
  width: 42%;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .leftsidecontent
  .circular-graph {
  position: relative;
  width: 120px;
  height: 120px;
  /* box-shadow: 0px 0px 90px #1A9A2F; */
  border-radius: 50%;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .leftsidecontent
  .circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: background 0.5s ease;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .leftsidecontent
  .circle::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  background: black;
  border-radius: 50%;
  z-index: 1;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .leftsidecontent
  .center-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .leftsidecontent
  #percentage {
  color: #ffc000;
  font-size: 20px;
  font-weight: bold;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .leftsidecontent
  #percentage-input {
  width: 0;
  padding: 0;
  border-radius: 5px;
  text-align: center;
  outline: none;
  background: transparent;
  color: white;
  box-shadow: none;
  border: 0;
  font-size: 0px;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .leftsidecontent
  #percentage-input:focus {
  border-color: #4fd1c5;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .rightsidecontent {
  width: 53%;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .rightsidecontent
  .listitems {
  padding-left: 0;
  list-style: none;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .rightsidecontent
  .listitems
  li {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 17px;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .rightsidecontent
  .listitems
  li
  .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .rightsidecontent
  .listitems
  li
  .icon
  img {
  filter: invert(1);
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .rightsidecontent
  .listitems
  li
  .contents {
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .rightsidecontent
  .listitems
  li
  .contents
  .heading {
  font-size: 15px;
  font-weight: 600;
  color: white;
  margin-bottom: 4px;
}

.NewDashoard
  .content-area
  .card-top-left
  .leftrightcontentcard
  .rightsidecontent
  .listitems
  li
  .contents
  .payment {
  font-size: 15px;
  color: white;
  font-weight: 600;
}

.NewDashoard .content-area .Rightsidecardtop {
  background-color: #272e48;
  box-shadow: 0px 5px 7px -3px rgba(25, 42, 70, 0.13);
  border-radius: 10px;
  height: auto;
  padding: 14px;
  height: 470px;
  overflow-y: auto;
}

.NewDashoard .content-area .Rightsidecardtop .card-Heading {
  font-size: 18px;
  color: white;
  font-weight: 600;
  margin-bottom: 20px;
  background: #23326a;
  padding: 10px;
  border-radius: 8px;
  /* box-shadow: 0px 3px 2px #ffffff; */
}

.NewDashoard .content-area .Rightsidecardtop .listright {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.NewDashoard .content-area .Rightsidecardtop .listright li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #0c3ae9;
  padding: 6px;
  border-radius: 5px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .NewDashoard .content-area .Rightsidecardtop .listright li .icon {
    width: 11%;
    height: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
  }
}
.NewDashoard .content-area .Rightsidecardtop .listright li .icon {
  width: 11%;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
}

.NewDashoard .content-area .Rightsidecardtop .listright li .heading {
  font-size: 16px;
  color: white;
  font-weight: 600;
  width: 40%;
  text-align: center;
}

.NewDashoard .content-area .Rightsidecardtop .listright li .arrowicontop {
  transform: rotate(210deg);
  color: green;
  box-shadow: 0px 0px 10px green;
  background: #00800073;
  width: 8%;
  text-align: center;
}

.NewDashoard .content-area .Rightsidecardtop .listright li .arrowicontop i {
  font-size: 25px;
}

.NewDashoard .content-area .Rightsidecardtop .listright li .arrowicondown i {
  font-size: 25px;
}

.NewDashoard .content-area .Rightsidecardtop .listright li .arrowicondown {
  transform: rotate(210deg);
  color: red;
  box-shadow: 0px 0px 10px red;
  width: auto;
  background: #ff000054;
}

.NewDashoard .content-area .Rightsidecardtop .listright li .amount {
  font-size: 16px;
  color: white;
  font-weight: 600;
  width: 40%;
  text-align: end;
}

.NewDashoard .content-area .headingmain {
  font-size: 20px;
  font-weight: 700;
  color: white;
}

.NewDashoard .content-area .card_Small {
  background-color: #272e48;
  box-shadow: 0px 5px 7px -3px rgba(25, 42, 70, 0.13);
  border-radius: 10px;
  height: 100%;
  padding: 14px;
  height: 105px;
}

.NewDashoard .content-area .card_Small .chart {
  height: 50px;
  display: none;
}

.NewDashoard .content-area .card_Small .card-heading {
  font-size: 16px;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0px;
}

.NewDashoard .content-area .card_Small .card-heading span {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  padding: 8px;
}

.NewDashoard .content-area .card_Small .card-heading span img {
  filter: invert(1);
}

.NewDashoard .content-area .card_Small .amount {
  font-size: 20px;
  color: white;
  font-weight: 600;
  margin-left: 47px;
}

.NewDashoard .content-area .card_Small button {
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: 0;
  text-align: left;
}

/* .NewDashoard .marginl{
  margin-left: 110px;
} */
.mailbtn {
  color: white;
}

.NewDashoard .metdardgig {
  height: 410px;
}

.NewDashoard .metdardgig img {
  border-radius: 10px;
}

.NewDashoard .card_Small .card-image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 160px;
}

.NewDashoard .card_Small .card-image .ranking {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 50px;
}

.NewDashoard .card_Small .card-body .card-heading {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 25px;
  font-weight: 700;
}

.NewDashoard .card_Small .card-image.card-image0 {
  background-image: url(../../../public/assets/image/Dashboardicon/bgimage1.png);
}

.NewDashoard .card_Small .card-image.card-image0 .rankimage {
  width: 120px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 80px;
}
.NewDashoard .card_Small.Ankerbtn {
  transition: all 0.3s ease-in-out;
}
.NewDashoard .card_Small.Ankerbtn:hover .card-heading {
  color: #ffc000;
}
.NewDashoard .card_Small.Ankerbtn:hover .btn {
  color: #ffc000;
}
/* .NewDashoard .card_Small .card-image.card-image1 {
  background-image: url(../../../public/assets/image/Dashboardicon/bgimage2.png);
}
.NewDashoard .card_Small .card-image.card-image2 {
  background-image: url(../../../public/assets/image/Dashboardicon/bgimage3.png);
}
.NewDashoard .card_Small .card-image.card-image3 {
  background-image: url(../../../public/assets/image/Dashboardicon/bgimage4.png);
}
.NewDashoard .card_Small .card-image.card-image4 {
  background-image: url(../../../public/assets/image/Dashboardicon/bgimage5.png);
}
.NewDashoard .card_Small .card-image.card-image5 {
  background-image: url(../../../public/assets/image/Dashboardicon/bgimage6.png);
}
.NewDashoard .card_Small .card-image.card-image6 {
  background-image: url(../../../public/assets/image/Dashboardicon/bgimage7.png);
}
.NewDashoard .card_Small .card-image.card-image7 {
  background-image: url(../../../public/assets/image/Dashboardicon/bgimage8.png);
} */
.NewDashoard .Bottomcard {
  background-color: #272e48;
  box-shadow: 0px 5px 7px -3px rgba(25, 42, 70, 0.13);
  border-radius: 8px;
}
.NewDashoard .Bottomcard .card-header {
  border-color: rgba(255, 255, 255, 0.12);
}
.NewDashoard .Bottomcard .card-header .card-title {
  font-size: 20px;
  color: #bdd1f8;
  font-weight: 500;
}
.NewDashoard .Bottomcard .card-body {
}
.NewDashoard .Bottomcard .card-body .tbhight {
  height: 300px;
}
.NewDashoard .Bottomcard table thead tr {
  background-color: #394369 !important;
  text-transform: uppercase !important;
  border-color: #272e48 !important;
}
.NewDashoard .Bottomcard table thead tr th {
  background-color: rgb(57 67 105) !important;
  white-space: nowrap;
}
.NewDashoard .Bottomcard table thead tr th .text-th {
  color: #fff !important;
}
.NewDashoard .Bottomcard table tbody tr td .imagerankicon {
  width: 55px;
  height: 55px;
  /* background-color: #23326a; */
  border-radius: 5px;
  padding: 5px;
}
.NewDashoard .Bottomcard table tbody tr td {
  font-size: 16px;
  color: white;
  font-weight: 500;
  white-space: nowrap;
}
.NewDashoard .Bottomcard table tbody tr button {
  color: white;
}
.NewDashoard .Bottomcard table tbody tr a i {
  color: white;
  font-size: 24px;
}

/* Custom scrollbar styling */
.NewDashoard .Bottomcard table .tbhight::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.NewDashoard .Bottomcard table .tbhight::-webkit-scrollbar-thumb {
  background-color: #272e48; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded corners */
}

.NewDashoard .Bottomcard table .tbhight::-webkit-scrollbar-thumb:hover {
  background-color: #272e48; /* Hover color for thumb */
}

.NewDashoard .Bottomcard table .tbhight::-webkit-scrollbar-track {
  background-color: #272e48; /* Scrollbar track color */
  border-radius: 4px; /* Rounded corners */
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .helpbtn {
    display: none;
  }
  .NewDashoard .content-area .card_Small {
    height: 100% !important;
  }
  .mailbtn {
    display: none;
  }

  .NewDashoard .content-area .card-top-left .leftrightcontentcard {
    flex-direction: column;
  }

  .NewDashoard
    .content-area
    .card-top-left
    .leftrightcontentcard
    .leftsidecontent {
    width: 100%;
    margin: auto;
    margin-top: 20px;
  }

  .NewDashoard
    .content-area
    .card-top-left
    .leftrightcontentcard
    .leftsidecontent
    .circular-graph {
    margin: auto;
  }

  .NewDashoard
    .content-area
    .card-top-left
    .leftrightcontentcard
    .rightsidecontent {
    width: 100%;
    margin-top: 20px;
  }

  .NewDashoard
    .content-area
    .card-top
    .bottomcardcontent
    .rightside
    .rightamoun {
    font-size: 14px;
  }

  .NewDashoard
    .content-area
    .card-top
    .bottomcardcontent
    .rightside
    .rightpayment {
    font-size: 14px;
  }

  .NewDashoard .content-area .card-top .payment {
    font-size: 16px;
    display: inline-block;
  }
  .NewDashoard .content-area .card-top .premium {
    font-size: 10px;
    display: inline-block;
  }

  .NewDashoard .content-area .card-top .card-heading .headingcard {
    font-size: 16px;
  }

  .NewDashoard .content-area .card-top .card-heading .iconimage {
    width: 80px;
    height: 80px;
  }

  .headingdah {
    font-size: 15px;
  }

  .NewDashoard .content-area {
    height: 100% !important;
  }

  .NewDashoard .metdardgig {
    height: 100%;
    margin-bottom: 20px;
  }
}
