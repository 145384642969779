.breadcrumb-title {
  font-size: 15px;
  color: white;
  border-right: 1.5px solid rgba(255, 255, 255, 0.85);
}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
  font-size: 14px;
}

.bx-home-alt:before {
  content: "\ea75";
}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
  font-size: 14px;
}

.breadcrumb-item.active {
  color: #ffffff;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.modal-dialog {
  margin: 23px auto !important;
  color: white;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: auto;
    margin: 30px auto !important;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  border-radius: 6px;
  margin: auto;
  padding: 20px 10px;
}

.exchange_width {
  min-height: auto;
}
.modal-content {
  position: relative;
  border: 1px solid #0b43d2;
  border-radius: 8px;
  min-height: max-content;
  -webkit-backdrop-filter: blur(10px);
  background-color: #272e48;
  backdrop-filter: blur(5px);
  box-shadow: inset 0px -1px 12px 3px rgba(25, 42, 70, 0.13) !important;
}
.btn-theme1 {
  background: #ffc000 !important;
  /* width: 60% !important; */
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.box {
  margin: 10px 0;
}

label {
  display: inline-block;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #1a233a !important;
  color: white !important;
}

.form-control::placeholder {
  color: white !important;
}

.form-control {
  color: white !important;
  background-color: #26365f !important;
  border: 0px solid #fff;
  border-radius: 5px;
  box-shadow: none;
  margin-top: 5px;
}

.btn-Green {
  background-color: #1a233a !important;
  font-size: 16px;
  font-weight: 600;
  color: white;
  border-radius: 0px;
  transition: all 0.3s ease-in-out;
  margin-left: 0.5rem;
  border-radius: 5px;
}

.btn_hxn {
  background-color: #1a233a;
  color: white;
  border: 1px solid #272e48 !important;
}
.btn_hxn:hover {
  /* background-color: ; */
}

@media screen and (max-width: 550px) {
  .btn-Green {
    margin-bottom: 10px;
  }
}
