.NewDashoard .sidebar {
  height: 100vh;
  overflow: hidden auto;
  transition: width 0.3s ease;
  width: 250px;
  background-color: #23326a !important;
  box-shadow: 0px 5px 7px -3px rgba(25, 42, 70, 0.13);
  padding: 16px;
  position: absolute;
  left: 0;
  z-index: 99;
}

.NewDashoard .sidebar .togglebtn {
  position: absolute;
  right: 6px;
}
.NewDashoard .togglebtn {
  background-color: #23326a;
  color: white;
  border-color: #23326a;
}
.NewDashoard .content-area {
  height: calc(100vh - 90px);
  /* Adjust for fixed header */
  overflow-y: auto;
  padding: 1rem 0rem;
}

.NewDashoard .sidebar.collapsed {
  width: 0px;
  transition: width 0.3s ease;
  padding: 0;
}

.NewDashoard .sidebar.collapsed .logosidebar {
  width: 0px;
  height: 0px;
}

.NewDashoard .sidebar.collapsed .togglebtn {
  margin-top: 15px;
  position: unset;
}

.NewDashoard .content-area.collapsed {
  margin-left: 0;
}

.NewDashoard .sidebar .logosidebar .name {
  /* font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  background: linear-gradient(90deg, #C8A23E, #A8731F);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: sans-serif; */
  height: 40px;
  margin-bottom: 20px;
}

.NewDashoard .sidebar .listitems {
}

.NewDashoard .sidebar .listitems li .sidebaricon {
  width: 60%;
  height: auto;
  margin: auto;
}
.NewDashoard .sidebar .listitems li .btnsidebar {
  background-color: #263777;
  font-size: 16px;
  color: white;
  font-weight: 700;
  text-decoration: none;
  height: 40px;
  border-radius: 8px;
  width: 180px;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.NewDashoard .sidebar .listitems .nav-item {
  margin-top: 10px;
}

.NewDashoard .sidebar .listitems .nav-item .dropdown .btnsecondary {
  width: 100%;
  display: flex;
  align-items: center;
  border: 0;
  gap: 1rem;
  color: white;
}

.NewDashoard .sidebar .listitems .nav-item .dropdown .btnsecondary .span2 {
  font-size: 16px;
  color: white;
  font-weight: 600;
}

/* .NewDashoard .sidebar.collapsed .listitems .nav-item .dropdown .btnsecondary .span2{
  display: none;
} */
/* .NewDashoard .sidebar.collapsed .listitems .nav-item .dropdown .btnsecondary::after{
  display: none;
} */
.NewDashoard .sidebar .listitems .nav-item .dropdown .btnsecondary .span1 {
  font-size: 25px;
  color: white;
}

.NewDashoard .sidebar .listitems .nav-item .dropdown .dropdown-menu {
  background: linear-gradient(180deg, #1a233a, #1a233a);
  width: 100%;
  border: 0;
}

.NewDashoard
  .sidebar
  .listitems
  .nav-item
  .dropdown
  .dropdown-menu
  li
  .dropdown-item:hover {
  background: #23326a;
  color: #ffc000;
}

.NewDashoard
  .sidebar
  .listitems
  .nav-item
  .dropdown
  .dropdown-menu
  li
  .dropdown-item {
  font-size: 14px;
  color: white;
  font-weight: 600;
  margin-bottom: 10px;
  border: 0;
  text-wrap: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.NewDashoard .sidebar .listitems .nav-item .nav-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease-in-out;
}
.NewDashoard .sidebar .listitems .nav-item .nav-link:hover span {
  color: #ffc000;
}
.NewDashoard .sidebar .listitems .nav-item .dropdown .btnsecondary:hover span {
  color: #ffc000;
}
.NewDashoard .sidebar .listitems .nav-item .nav-link .span2 {
  font-size: 16px;
  color: white;
  font-weight: 600;
}

/* .NewDashoard .sidebar.collapsed .listitems .nav-item .nav-link .span2{
display: none;

} */

.NewDashoard .sidebar .listitems .nav-item .nav-link .span1 {
  font-size: 20px;
  color: white;
}

/* Header css start */
.header {
  z-index: 1020;
  background-color: #23326a;
  box-shadow: 0px 5px 7px -3px rgba(25, 42, 70, 0.13);
}

.header .Groupitems {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header .Groupitems .helpbtn {
  cursor: pointer;
  display: none;
}

.header .Groupitems .mailbtn {
  cursor: pointer;
}

.header .Groupitems .helpbtn i {
  font-size: 25px;
}

.header .Groupitems .mailbtn i {
  font-size: 25px;
}

.header .Groupitems .dropdown .btnsecondary {
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  border: 0;
  gap: 0.5rem;
}

.header .Groupitems .dropdown .btnsecondary::after {
  display: none;
}

.header .Groupitems .dropdown .dropdown-menu {
  background: linear-gradient(180deg, #1a233a, #1a233a);
  display: none;
}

.header .Groupitems .dropdown .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: white;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header .Groupitems .dropdown .dropdown-menu .dropdown-item:hover {
  background: #23326a;
}

.header .Groupitems .dropdown .btnsecondary .iconimage {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}

@media screen and (min-width: 320px) and (max-width: 575.99px) {
  .NewDashoard .content-area .card-top-left {
    border-radius: 25px !important;
  }
  /* .sidebar {
    width: 0 !important;
    padding: 0 !important;
  } */

  /* .sidebar.collapsed {
    transition: width 0.3s ease !important;
    width: 230px !important;
    padding: 16px !important;
  } */

  /* .NewDashoard .sidebar.collapsed .logosidebar {
    width: 100px !important;
    height: 100px !important;
  } */
}
